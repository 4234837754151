<template>
  <div
    :class="{
      'validity-rules-editor': true,
      even: uimodel.dataLevel === 0 || uimodel.dataLevel % 2 === 0,
      odd: uimodel.dataLevel % 2 !== 0,
    }"
  >
    <v-btn text @click="showPreview = !showPreview">
      <v-icon left>{{ showPreview ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
      {{ showPreview ? "Hide" : "Show" }} rules preview
    </v-btn>
    <div v-if="showPreview" class="d-flex flex-column elevation-6 pa-3 ma-2">
      <ValidityRulePreview :validity-rules="validityRules" />
    </div>
    <div
      v-for="(ruleModel, index) in validityRulesTree"
      :key="ruleModel.sequenceNumber + index + reloadKey"
    >
      <div
        class="drop-zone"
        ref="dropZone"
        @drop="onDrop($event, ruleModel)"
        @dragover.prevent
        @dragenter.prevent="highlightDropZone($event, true)"
        @mouseleave="highlightDropZone($event, false)"
        @dragleave="highlightDropZone($event, false)"
        :data-test-id="'dropZoneBefore_' + ruleModel.sequenceNumber"
      >
        <div class="drop-zone-content">
          <v-icon left>mdi-download</v-icon>
          {{ dropZoneText }}
        </div>
      </div>
      <ValidityRule :uimodel="ruleModel" />
    </div>

    <div
      class="drop-zone"
      ref="dropZone"
      @drop="onDrop"
      @dragover.prevent
      @dragenter.prevent="highlightDropZone($event, true)"
      @mouseleave="highlightDropZone($event, false)"
      @dragleave="highlightDropZone($event, false)"
      data-test-id="dropZoneBeforeEnd"
    >
      <div class="drop-zone-content">
        <v-icon left>mdi-download</v-icon>
        {{ dropZoneText }}
      </div>
    </div>

    <div class="d-flex flex-grow-1">
      <v-btn
        v-if="!inherited"
        class="d-flex flex-grow-1"
        text
        color="primary"
        data-test-id="addValidityRuleBtn"
        @click="addValidityRule(null)"
      >
        <v-icon left>mdi-plus</v-icon>
        ADD VALIDITY RULE
      </v-btn>
    </div>
  </div>
</template>

<script>
import ValidityRule from "./validity-rules/ValidityRule";
import ValidityRulePreview from "./validity-rules/ValidityRulePreview";
import DataEditorMixin from "mixins/data-editor-mixin";
import ValidityRuleMixin from "mixins/validity-rules-mixin";
export default {
  mixins: [DataEditorMixin, ValidityRuleMixin],

  components: {
    ValidityRule,
    ValidityRulePreview,
  },

  provide() {
    return {
      addValidityRule: this.addValidityRule,
      updateValidityRule: this.updateValidityRule,
      removeValidityRule: this.removeValidityRule,
      moveValidityRule: this.moveValidityRule,
    };
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    uimodel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      validityRules: this.$cloneObject(this.value),
      validityRulesTree: [],
      reloadKey: 0,
      showPreview: false,
    };
  },

  mounted() {
    this.parseRulesArrayToTree();
  },

  watch: {
    value: {
      handler: function (value) {
        if (!this.$isEqual(this.validityRules, value)) {
          this.validityRules = this.$cloneObject(value);
          this.parseRulesArrayToTree();
        }
      },
      deep: true,
    },

    validityRules: {
      handler: function (rules) {
        if (!this.$isEqual(rules, this.value)) {
          this.$emit("input", rules);
        }
      },
      deep: true,
    },
  },

  methods: {
    parseRulesArrayToTree() {
      let validityRulesTree = {};

      const ruleUiModels = this.buildSubUimodels(this.uimodel);
      const rulesData =
        this.uimodel.data ??
        this.uimodel.inheritedData ??
        this.uimodel.mergedData;

      this.$cloneObject(rulesData)?.reduce?.((tree, rule) => {
        //Use the rules sequence number and build a path
        //array
        const sequenceNrPath = rule.sequenceNumber
          .split("_")
          .reduce((array, seqNr) => {
            const prevSeqNr = array[array.length - 1];
            let currentSeqNr = seqNr;
            if (prevSeqNr) currentSeqNr = prevSeqNr + "_" + seqNr;
            array.push(currentSeqNr);
            return array;
          }, []);

        //Build a uimodel for every sequence number in the path array
        //and add it to the tree
        sequenceNrPath.reduce((obj, seqNr, index, path) => {
          if (!obj.children) this.$set(obj, "children", []);
          const existing = obj.children.find?.(({ sequenceNumber }) => {
            return sequenceNumber === seqNr;
          });
          if (existing) return existing;
          let child = {
            sequenceNumber: seqNr,
          };
          if (seqNr === rule.sequenceNumber) {
            const index = rulesData.findIndex((item) => {
              return item.sequenceNumber === seqNr;
            });

            let ruleUiModel = ruleUiModels[index];
            this.$delete(ruleUiModel, "savedData");
            child = {
              ...child,
              ...ruleUiModel,
              dataLevel: ruleUiModel.dataLevel + (path.length - 1),
            };
          }

          obj.children.push(child);
          //Sort the children of the object after each
          //iteration, so that it is correctly displayed in the tree
          this.sortValidityRules(obj.children);
          return child;
        }, tree);
        return tree;
      }, validityRulesTree);

      let firstLevelRules = this.$cloneObject(
        validityRulesTree?.children
      )?.sort?.((ruleA, ruleB) => {
        const seqNrA = Number(ruleA.sequenceNumber);
        const seqNrB = Number(ruleB.sequenceNumber);
        return seqNrA - seqNrB;
      });

      this.validityRulesTree = firstLevelRules ?? [];
      this.reloadKey++;
    },

    sortValidityRules(rules) {
      const clone = this.$cloneObject(rules);
      return clone.sort((childA, childB) => {
        const seqNrA = childA.sequenceNumber;
        const seqNrB = childB.sequenceNumber;
        const levelsA = seqNrA.split("_");
        const levelsB = seqNrB.split("_");

        const length =
          levelsA.length >= levelsB.length ? levelsA.length : levelsB.length;

        for (let i = 0; i < length; i++) {
          const a = levelsA[i];
          const b = levelsB[i];
          if (!a) return -1;
          if (!b) return 1;
          if (a !== b) return Number(a) - Number(b);
        }
      });
    },

    updateValidityRule(ruleModel) {
      const index = this.validityRules.findIndex((rule) => {
        return rule.sequenceNumber === ruleModel.sequenceNumber;
      });
      this.$set(this.validityRules, index, ruleModel.data);
    },

    async addValidityRule(rule) {
      if (rule === null) {
        //A new rule should be added at root level
        const sequenceNumber = this.getNextSequenceNumber(
          null,
          this.validityRulesTree
        );
        rule = this.validityRuleProperties.reduce((rule, property) => {
          this.$set(rule, property, null);
          return rule;
        }, {});
        this.$set(rule, "sequenceNumber", "" + sequenceNumber);
      }
      this.validityRules.push(rule);
      await this.$nextTick();
      this.parseRulesArrayToTree();
      await this.$nextTick();
      this.highlightRule(rule.sequenceNumber);
    },

    highlightRule(sequenceNumber) {
      //Focus the new validity rule
      const newRuleComponent = document.getElementById(
        "validity_rule_" + sequenceNumber
      );

      //Highlight and focus the new rule
      newRuleComponent.classList.add("new");
      setTimeout(() => newRuleComponent.classList.remove("new"), 5000);
      const ruleTypeInput = newRuleComponent?.__vue__?.$refs?.ruleTypeInput;
      ruleTypeInput?.$refs?.field?.focus();
    },

    removeValidityRule(ruleModel) {
      //Remove a rule and all of its children
      const sequenceNumber = ruleModel.sequenceNumber;
      let newValidityRules = this.$cloneObject(this.validityRules).filter(
        (rule) => !rule.sequenceNumber.startsWith(sequenceNumber)
      );
      this.recalculateSiblingsAfterDelete(sequenceNumber, newValidityRules);
      this.validityRules = this.sortValidityRules(newValidityRules);
      this.$nextTick(() => this.parseRulesArrayToTree());
    },

    async moveValidityRule(parentRule, newSequenceNumber, movedRule) {
      const oldSequenceNumber = movedRule.sequenceNumber;
      const parentSequenceNumber = parentRule?.sequenceNumber ?? null;

      //1. Remove old validity rule
      let newValidityRules = this.$cloneObject(this.validityRules).filter(
        ({ sequenceNumber }) => sequenceNumber !== oldSequenceNumber
      );

      //2. Add new rule
      const newMovedRule = {
        ...movedRule.data,
        sequenceNumber: newSequenceNumber,
        moved: true,
        moveRoot: true,
      };

      newValidityRules.push(newMovedRule);

      //3. Change children of moved rule
      this.getChildrenOfRule(oldSequenceNumber, this.validityRules).forEach(
        ({ sequenceNumber }) => {
          let existing = newValidityRules.find(
            (rule) => rule.sequenceNumber === sequenceNumber
          );

          if (!existing) return;
          const newChildSeqNr = sequenceNumber.replace(
            oldSequenceNumber,
            newSequenceNumber
          );
          this.$set(existing, "sequenceNumber", newChildSeqNr);
          this.$set(existing, "moved", true);
        }
      );

      //4. Rearrange new siblings

      //Get last part of sequence number to determine the order within
      //the child array
      let newSiblingsArray = [newMovedRule];
      const siblings = this.sortValidityRules(
        this.getChildrenOfRule(parentSequenceNumber, newValidityRules, true)
      );

      siblings.forEach(({ sequenceNumber }) => {
        let rule = newValidityRules
          .filter((rule) => !rule.moved && !rule.updated)
          .find((rule) => rule.sequenceNumber === sequenceNumber);
        if (!rule || rule.moved || rule.updated) return;

        let siblingIndex = 1;
        while (
          this.ruleIndexExists(
            siblingIndex,
            parentSequenceNumber,
            newSiblingsArray
          )
        ) {
          siblingIndex++;
        }

        //create new sequence number and add the rule to the array
        let newSiblingSequenceNumber = "" + siblingIndex;

        if (parentSequenceNumber !== null) {
          newSiblingSequenceNumber = parentSequenceNumber + "_" + siblingIndex;
        }

        if (newSiblingSequenceNumber !== rule.sequenceNumber) {
          //This rules position is changed by the move, so recalculate
          //its sequence number and consequently those of its children
          this.getChildrenOfRule(sequenceNumber, newValidityRules).forEach(
            (child) => {
              let existing = newValidityRules.find(
                (rule) => rule.sequenceNumber === child.sequenceNumber
              );

              if (!existing || existing.updated || existing.moved) return;
              const newChildSeqNr = existing.sequenceNumber.replace(
                sequenceNumber,
                newSiblingSequenceNumber
              );
              this.$set(existing, "sequenceNumber", newChildSeqNr);
              this.$set(existing, "updated", true);
            }
          );
          this.$set(rule, "sequenceNumber", newSiblingSequenceNumber);
          this.$set(rule, "updated", true);
        }
        newSiblingsArray.push(rule);
      });

      //5. Rearrange old siblings

      //Recalculate the sequence number of the moved rules old siblings
      const oldParentSequenceNumber =
        this.getParentSequenceNumber(oldSequenceNumber);
      if (
        (oldParentSequenceNumber || oldParentSequenceNumber === null) &&
        oldParentSequenceNumber !== parentSequenceNumber
      ) {
        this.recalculateSiblingsAfterDelete(
          oldSequenceNumber,
          newValidityRules
        );

        //Get the moved rule again, since its sequence number
        //may have been recalculated by the previous action
        const moveRoot = newValidityRules.find((rule) => rule.moveRoot);
        newSequenceNumber = moveRoot.sequenceNumber;
      }

      //6. Update validity rules
      this.validityRules = this.sortValidityRules(newValidityRules).map(
        (rule) => {
          this.$delete(rule, "moved");
          this.$delete(rule, "updated");
          this.$delete(rule, "moveRoot");
          return rule;
        }
      );
      await this.$nextTick();
      this.parseRulesArrayToTree();
      await this.$nextTick();
      this.highlightRule(newSequenceNumber);
    },

    recalculateSiblingsAfterDelete(removedSequenceNumber, validityRules) {
      //Recalculate the sequence number of all siblings of a removed validity rule
      const oldRuleIndex = this.getRuleIndex(removedSequenceNumber);
      const parentSequenceNumber = this.getParentSequenceNumber(
        removedSequenceNumber
      );

      const siblings = this.sortValidityRules(
        this.getChildrenOfRule(parentSequenceNumber, validityRules, true)
      );

      siblings.forEach(({ sequenceNumber }) => {
        let rule = validityRules.find(
          (rule) => rule.sequenceNumber === sequenceNumber
        );
        if (!rule || rule.moved) return;
        let siblingIndex = this.getRuleIndex(rule.sequenceNumber);
        if (siblingIndex >= oldRuleIndex) {
          while (
            this.ruleIndexExists(
              siblingIndex,
              parentSequenceNumber,
              validityRules
            ) &&
            siblingIndex > 1
          ) {
            siblingIndex--;
          }

          //create new sequence number and add the rule to the array
          let newSequenceNumber = "" + siblingIndex;
          if (parentSequenceNumber !== null) {
            newSequenceNumber = parentSequenceNumber + "_" + siblingIndex;
          }
          this.getChildrenOfRule(sequenceNumber, validityRules).forEach(
            (child) => {
              let existing = validityRules.find(
                (rule) => rule.sequenceNumber === child.sequenceNumber
              );
              const newChildSeqNr = existing.sequenceNumber.replace(
                sequenceNumber,
                newSequenceNumber
              );
              this.$set(existing, "sequenceNumber", newChildSeqNr);
            }
          );
          this.$set(rule, "sequenceNumber", newSequenceNumber);
        }
      });
    },

    onDrop(evt, nextSiblingRule) {
      const dropData = evt.dataTransfer.getData("validityRule");
      if (dropData) {
        const validityRule = JSON.parse(dropData);
        //Remove pseudo drag ghost element
        const dragGhostNode = document.getElementById(
          "ghost_" + validityRule.sequenceNumber
        );
        dragGhostNode.remove();
        const children = this.validityRulesTree;
        let sequenceNumber = nextSiblingRule?.sequenceNumber;
        if (!nextSiblingRule) {
          sequenceNumber = "" + this.getNextSequenceNumber(null, children);
        }

        const parentOfDropRule = this.getParentSequenceNumber(
          validityRule.sequenceNumber
        );

        const oldRuleIndex = this.getRuleIndex(validityRule.sequenceNumber);
        const newRuleIndex = this.getRuleIndex(sequenceNumber);

        if (!parentOfDropRule && oldRuleIndex < newRuleIndex) {
          //The rule is dropped in the same parent and its sequence number is now higher (e.g. 1_0 --> 1_1)
          sequenceNumber = "" + (newRuleIndex - 1);
        }

        /* 
          Check if:
            1. The dropped rule is the FIRST child of the current validity rule and also dropped in FIRST place on the same rule
            2. The dropped rule is the LAST child of the current validity rule and dropped in LAST place on the same rule
          If either one of those is true just abort the drop
        */
        if (validityRule.sequenceNumber === nextSiblingRule?.sequenceNumber)
          return;
        else if (!nextSiblingRule && children.length > 0) {
          const lastChildRule = children[children.length - 1];
          if (validityRule.sequenceNumber === lastChildRule.sequenceNumber) {
            return;
          }
        }
        this.moveValidityRule(null, sequenceNumber, validityRule);
      }
    },
  },

  computed: {
    inherited() {
      return this.uimodel.data === null || this.uimodel.data === undefined;
    },

    dropZoneText() {
      return "Drop as root rule";
    },
  },
};
</script>

<style scoped>
.validity-rules-editor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.validity-rules-editor .drop-zone {
  padding: 4px;
}

.validity-rules-editor .drop-zone.over {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px dashed grey;
}

.validity-rules-editor .drop-zone:not(.over) > .drop-zone-content {
  display: none;
}
</style>

<style>
.validity-rules-editor .validity-rule.new {
  animation: border-pulsate 2s infinite !important;
  border: 2px solid var(--v-psgreen-base);
}

@keyframes border-pulsate {
  0%,
  100% {
    border-color: var(--v-psgreen-base);
  }
  50% {
    border-color: lightgrey;
  }
}
</style>